
import { $gettext } from 'vue-gettext';
import { Contact, defaultContact, useContactStore } from '@/stores/contact';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { isEqual, OptionalKeys } from '@/utils/object';
import { useCustomerStore } from '@/stores/customer';
import { useFormErrors } from '@/utils/form-errors';
import { useLanguageStore } from '@/stores/language';
import { useRouter } from 'vue-router';
import { useSalutationStore } from '@/stores/salutation';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-contact-editor',
  props: {
    modelValue: Object as PropType<Contact>,
    loading: Boolean,
    unwrap: Boolean,
    customerId: String,
    redirect: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// CONTACT
    ///-------------------------------------------------------------------

    const initialContact: OptionalKeys<Contact, '_id' | '_rev'> = {
      ...defaultContact,
      company: router.currentRoute.value.query.customer as string || '',
    };

    const contact = ref<OptionalKeys<Contact, '_id' | '_rev'>>({ ...clone(initialContact), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => props.modelValue, reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(contact.value, props.modelValue)) { return }
      contact.value = { ...clone(initialContact), ...props.modelValue ? clone(props.modelValue) : {} };
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Contact>();

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore(true);

    ///-------------------------------------------------------------------
    /// EMAIL
    ///-------------------------------------------------------------------

    const emailExists = ref(false);
    watch(() => [contact.value.email, contact.value.company], ([email, company]) => {
      emailExists.value = store.emailExists(email, company, isEdit.value ? contact.value._id : [])
    })

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const salutationStore = useSalutationStore();

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const languageStore = useLanguageStore();

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useContactStore();

    async function save() {
      let response;
      if(props.customerId) {
        const customerResponse = await customerStore.readById(props.customerId);
        if(customerResponse && customerResponse.success && customerResponse.data) {
          contact.value.company = customerResponse.data._id;
        }
      }
      if (contact.value._id) {
        response = await store.update(contact.value);
      } else {
        response = await store.create(contact.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: contact.value._id
            ? $gettext('Contact successfully updated')
            : $gettext('Contact successfully created'),
          content: contact.value._id
            ? $gettext('The contact with the email address <strong>%{email}</strong> was successfully updated.', { email: contact.value.email })
            : $gettext('The contact with the email address <strong>%{email}</strong> was successfully created.', { email: contact.value.email }),
          icon: 'circle-check',
        });
        if(props.redirect) {
          router.replace({ name: router.currentRoute.value.query.customer ? 'admin.customer.overview' : 'admin.contact.overview' });
        }
        return response.data;
      } else {
        BpToast.show({
          color: 'red',
          title: contact.value._id
            ? $gettext('Failed to update contact')
            : $gettext('Failed to create contact'),
          content: contact.value._id
            ? $gettext('The contact with the email address <strong>%{email}</strong> could not be updated: %{error}', { email: contact.value.email, error: response?.error })
            : $gettext('The contact with the email address <strong>%{email}</strong> could not be created: %{error}', { email: contact.value.email, error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    function saveDisabled() {
      return errors.value.size > 0;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      contact,
      customerStore,
      emailExists,
      errors,
      isEdit,
      languageStore,
      reset,
      salutationStore,
      save,
      saveDisabled,
      setError,
      store,
    }
  }

})


