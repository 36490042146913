import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "bp-contact-editor__name-wrapper" }
const _hoisted_2 = { class: "flex gap-md" }
const _hoisted_3 = {
  key: 0,
  style: {"opacity":"0.5"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unwrap ? 'span' : 'bp-card'), {
    class: "bp-contact-editor",
    loading: _ctx.loading || _ctx.customerStore.isLoading(),
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("New contact")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { contact: _ctx.contact.email }
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("Edit contact \"%{contact}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled() || _ctx.emailExists,
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.contact.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.contact && _ctx.customerStore.loaded)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-contact-editor__form",
            onSubmit: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_openBlock(), _createBlock(_component_bp_input, {
              key: _ctx.emailExists,
              error: _ctx.emailExists ? _ctx.$gettext('This email address does already exist.') : undefined,
              label: _ctx.$gettext('Email address'),
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('email', _ctx.$gettext('Email address'), $event))),
              required: "",
              type: "email",
              modelValue: _ctx.contact.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contact.email) = $event))
            }, null, 8, ["error", "label", "modelValue"])),
            (!_ctx.customerId)
              ? (_openBlock(), _createBlock(_component_bp_select, {
                  key: 0,
                  data: _ctx.customerStore.customers,
                  label: _ctx.$gettext('Company'),
                  loading: _ctx.customerStore.isLoading(),
                  onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('company', _ctx.$gettext('Company'), $event))),
                  "close-on-click": "",
                  required: "",
                  "search-property": "company",
                  modelValue: _ctx.contact.company,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contact.company) = $event))
                }, null, 8, ["data", "label", "loading", "modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('Salutation') + ' / ' + _ctx.$gettext('Forename') + ' / ' + _ctx.$gettext('Surname'),
              required: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_bp_select, {
                    data: _ctx.salutationStore.salutations,
                    onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('salutation', _ctx.$gettext('Salutation'), $event))),
                    clearable: "",
                    "close-on-click": "",
                    "search-property": "name",
                    modelValue: _ctx.contact.salutation,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contact.salutation) = $event)),
                    style: {"width":"unset !important"}
                  }, {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("div", _hoisted_2, [
                        (option.icon)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              icon: option.icon
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, [
                          _createTextVNode(_toDisplayString(option.name) + " ", 1),
                          (!option._disabled)
                            ? (_openBlock(), _createElementBlock("em", _hoisted_3, "(" + _toDisplayString(option.example) + ")", 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["data", "modelValue"]),
                  _createVNode(_component_bp_input, {
                    modelValue: _ctx.contact.forename,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contact.forename) = $event)),
                    onChangeValid: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setError('forename', _ctx.$gettext('Forename'), $event))),
                    maxlength: 100
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_bp_input, {
                    modelValue: _ctx.contact.surname,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.contact.surname) = $event)),
                    onChangeValid: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setError('surname', _ctx.$gettext('Surname'), $event))),
                    required: "",
                    maxlength: 100
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_bp_select, {
              data: _ctx.languageStore.languages,
              label: _ctx.$gettext('Language'),
              onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('language', _ctx.$gettext('Language'), $event))),
              "close-on-click": "",
              transform: (language) => _ctx.languageStore.getNameOf(language._id),
              modelValue: _ctx.contact.language,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.contact.language) = $event))
            }, null, 8, ["data", "label", "transform", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Position'),
              onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('position', _ctx.$gettext('Position'), $event))),
              modelValue: _ctx.contact.position,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.contact.position) = $event)),
              maxlength: 100
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Phone'),
              onChangeValid: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setError('phone', _ctx.$gettext('Phone'), $event))),
              type: "tel",
              modelValue: _ctx.contact.phone,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.contact.phone) = $event))
            }, null, 8, ["label", "modelValue"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}